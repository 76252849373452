const COMPANY_NAME = "KW Select";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title:
        "Faça já a avaliação do seu imóvel, apartamento ou moradia e conheça já o valor da sua casa.",
      subTitle: "AVALIE JÁ O SEU IMÓVEL, SEM CUSTOS",
    },
    disclaimer: `A utilização voluntária deste simulador para avaliação do seu imóvel online, apartamento, moradia ou similar, implica aceitação e consentimento à empresa ${COMPANY_NAME} para recolha e tratamento dos dados pessoais para prestação de serviços de avaliação de imóveis, mediação imobiliária e relacionados, bem como comunicação promocional e marketing sobre oportunidades de negócios imobiliários a serem enviados pela nossa rede de consultores, exclusivamente para os fins mencionados, sem partilha dos mesmos a terceiros.`,
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "Make the assessment of your property and know its value.",
      subTitle: "KNOW YOUR PROPERTY VALUE, FREE",
    },
    disclaimer: `The voluntary use of this simulator to evaluate your property online, apartment, villa or similar, implies acceptance and consent to the company ${COMPANY_NAME} for the collection and processing of personal data for the provision of real estate appraisal, real estate mediation and related services, as well as promotional and marketing communication about real estate business opportunities to be sent by our network of consultants, exclusively for the aforementioned purposes, without sharing them with third parties.`,
  },
};
