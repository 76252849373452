import { createMuiTheme } from "@material-ui/core/styles";
import { PALLETE_PRIMARY_COLOR, PALLETE_SECONDARY_COLOR, PRIMARY } from "./colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PALLETE_PRIMARY_COLOR,
    },
    secondary: {
      main: PALLETE_SECONDARY_COLOR,
    },
  },
  subHeader: {
    lineHeight: 1.4,
    fontSize: "1rem",
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        padding: "10px 16px",
        borderRadius: 10,
      },
      contained: {
        color: "white !important",
      },
    },
    MuiChip: {
      root: {
        height: "40px",
        fontSize: "0.8rem",
        borderRadius: "10rem",
      },
    },
  },
  priceBar: {
    width: "100%",
    height: "10px",
    border: 0,
    borderRadius: "10px",
    margin: 0,
    backgroundImage: `linear-gradient(to left, #009de0, #00fff6)`,
    "-webkit-print-color-adjust": "exact",
    "color-adjust": "exact",
  },
  polygon: {
    fill: "#00fff6",
  },
});

export default theme;
